export type AnalyticsEvent = {
  eventName: string;
  properties?: Record<string, unknown>;
  timestamp: number;
};

export enum AnalyticsEventName {
  TooltipViewed = 'Tooltip Viewed',
  ApplicationStarted = 'Application Started',
  StepViewed = 'Step Viewed',
  ButtonClicked = 'Button Clicked',
  LinkClicked = 'Link Clicked',
  CheckboxClicked = 'Checkbox Clicked',
  FieldFilled = 'Field Filled',
  FundingAmountChanged = 'Funding Amount Changed',
  SliderChanged = 'Slider Changed',
  PaymentTermsSelected = 'Payment Terms Selected',
  LoggedIn = 'Logged In',
  QuoteFundingAmountSliderChanged = 'Quote Page Slider Amount Changed',
  QuoteFundingAmountInputChanged = 'Quote Page Input Amount Changed',
  ReportApplicationDecisionStatus = 'Report Application Decision Status',
  RenewalBannerShown = 'Renewal Banner Shown',
  OpenBankingCTAShown = 'Open Banking CTA Shown',
  OpenBankingAttachToApplicationSuccessful = 'Open Banking Attach To Application Successful',
  OpenBankingAttachToApplicationFailed = 'Open Banking Attach To Application Failed',
  OpenBankingCodatSdkConnectionError = 'Open Banking Codat SDK Connection Error',
  OpenBankingCodatSdkCompleted = 'Open Banking Codat SDK Completed',
  OpenBankingCodatSdkConnected = 'Open Banking Codat SDK Connected',
  OpenBankingCodatSdkClosed = 'Open Banking Codat SDK Closed',
}

export interface Analytics {
  trackButtonClick: (
    partner: string,
    buttonText: string,
    trackingLabelId: string,
  ) => void;

  trackStepViewed: (partner: string, stepName: string) => void;

  trackNewOrReturningUser: () => void;

  enableTracking: () => void;

  trackFieldFilled: (
    partner: string,
    fieldName: string,
    fieldType: string,
  ) => void;

  trackSliderRevenueChange: (
    partner: string,
    sliderEvent: string,
    previousAverageMonthlyRevenue: number,
    averageMonthlyRevenue: number,
  ) => void;

  trackSliderMonthsInBusinessChange: (
    partner: string,
    sliderEvent: string,
    previousMonthsInBusiness: number,
    monthsInBusiness: number,
  ) => void;

  trackFundingQuoteChange: (
    partner: string,
    fieldType: string,
    startingAmount: number,
    finalAmount: number,
  ) => void;

  trackCheckboxClick: (
    partner: string,
    value: boolean,
    checkboxName: string,
  ) => void;

  trackTooltipViewed: (
    partner: string,
    payload: {
      text: string;
      fieldName: string;
      tooltipId: string;
    },
  ) => void;

  trackSliderFundingQuoteAmountChange: (
    partner: string,
    sliderEvent: string,
    min: number,
    max: number,
    previousFundingQuoteAmount: number,
    fundingQuoteAmount: number,
  ) => void;

  trackInputFundingQuoteAmountChange: (
    partner: string,
    inputEvent: string,
    min: number,
    max: number,
    previousFundingQuoteAmount: number,
    fundingQuoteAmount: number,
  ) => void;

  trackApplicationDecisionStatus: (partner: string, value: string) => void;

  trackLoggedIn: (partner: string, email?: string) => void;

  trackRenewalBannerShown: (partner: string) => void;

  setUserProperty: (propertyName: string, value: string) => void;

  getProperty: (propertyName: string) => string | null;

  trackOpenBankingCTAShown: (partner: string) => void;

  trackOpenBankingRequestedAttachedToApplicationSuccess: (partner: string) => void;

  trackOpenBankingRequestedAttachedToApplicationFailed: (partner: string) => void;

  trackOpenBankingCodatSdkConnectionError: (partner: string) => void;

  trackOpenBankingCodatSdkCompleted: (partner: string) => void;

  trackOpenBankingCodatSdkConnected: (partner: string) => void;

  trackOpenBankingCodatSdkClosed: (partner: string) => void;
}
