import { InitOptions } from 'i18next';

export function getOptions(partner: string, lng = 'en_GB') {
  const namespace = [
    partner,
    'default',
    'cookieBanner',
    'businessType',
    'industry',
    'intendedUseOfFunds',
    'ownershipStatus',
    'ordinals',
    'codatLink',
    'americanStates',
    'canadianProvinces',
    'v2formFields',
    'v2footer',
    'v2ordinals',
    'v2ownershipStatus',
    'v2businessDetails',
    'v2text',
    'v2yourDetails',
    'v2yourQuote',
    'v2yourFlexiQuote',
    'v2review',
    'v2reviewTerms',
    'v2explainerSection',
    'v2bankingDetails',
    'v2signContracts',
    'v2shareholderDetails',
    'v2caseStudy',
    'v2editDetails',
    'v2complete',
    'v2flexiComplete',
    'v2errors',
    'dashboard',
    'help-center',
    'paymentHistory',
    'user-details',
    'common',
    'error',
    'renewalStart',
    'renewalJourney',
    'renewalConfirm',
    'renewalComplete',
    'openBankingModal',
    'openBankingComplete',
  ];

  return {
    fallbackLng: 'en_GB',
    fallbackNS: [...namespace],
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    lng,
    defaultNS: partner,
    ns: [...namespace],
  } as InitOptions<any>;
}
